// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// Services
import { Api } from 'services/api';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Components
import Jumbotron from 'components/jumbotron';
import BlockingSpinner from 'components/blocking-spinner';
import KpiList from 'components/kpi-library/KpiList';
import { Empty } from 'antd';

// Interfaces
import { KpiRecord } from 'components/kpi-library/KpiLibrary.interfaces';

// Styles
import 'assets/styles/_layout.scss';

const API: Api = new Api();

interface Props {
  type: string;
  entity: string;
  client_id?: number;
  permissions?: UserPermissions;
  record?: RecordFormEntity;
  rightActions?: any[];
};

interface State {
  kpis: KpiRecord[];
  isFetching: boolean;
};

class KpisView extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    kpis: [],
    isFetching: true,
  };

  componentDidMount = async () => {
    const { type, entity, client_id, record } = this.props;
    this.mounted = true;

    if (!client_id) return;

    try {

      if (!record) throw new Error('Failed');

      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null)));

      const response = await API.get(`client/${client_id}/${_.kebabCase(entity)}/${_.kebabCase(type)}/${record.id}/kpi-library/kpi`);

      this.mounted && this.setState({
        kpis: response.kpis,
      });

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderKpis = () => {
    const { kpis, isFetching } = this.state;

    if (_.isEmpty(kpis)) {
      return <div className="d-f jc-c ai-c mH-500"><Empty description={ 'There are no records assigned' } /></div>;
    }

    return <KpiList kpis={ kpis } isFetching={ isFetching }/>;
  };

  render = () => {
    const { client_id, rightActions } = this.props;
    const { isFetching } = this.state;

    if (!client_id) return <></>;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        <Jumbotron
          title={ this.props.record?.title }
          tabs={[
            {
              label: 'Kpi Metrics',
              node: this.renderKpis(),
            },
          ]}
          rightActions={ !!rightActions ? rightActions : [] }
        />
      </BlockingSpinner>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

export default connect(mapStateToProps, null)(KpisView);
