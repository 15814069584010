// Libs
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import TermsModal from 'components/terms-modal';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { validatePasswordStrength } from 'utils/utils';

const { Link } = Typography;
const API: Api = new Api();

interface Props {
  history: Record<string, any>;
  location: {
    hash: string;
    pathname: string;
    search: string;
    state: Record<string, any>;
  };
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
};

interface State {
  showTermsConditions: boolean;
  showGDPRConditions: boolean;
  isLoading: boolean;
  hasErrors: boolean;
};

class AcceptInvitation extends React.Component<RouteComponentProps<{}> & Props, State> {

  mounted: boolean = false;

  state: State = {
    showTermsConditions: false,
    showGDPRConditions: false,
    isLoading: false,
    hasErrors: true,
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  handleAcceptInvitation = (values: any) => {
    const {
      history,
      match,
    } = this.props;

    const email = match.params.email;
    const token = match.params.token;
    const client_id = match.params.client_id;
    const { password, toc } = values;

    this.setState(
      {
        isLoading: true,
      }, () => {

        API.onboardUser(token, email, password, client_id, toc[0])
          .then((response: any) => {
            Notification('success', `Your profile setup is now complete.`, 'Request successful');
            history.push('/login');
          })
          .catch((err: any) => {
            Notification('error', 'Something went wrong', 'Failed');
            console.error(err);
          })
          .finally(() => this.mounted && this.setState({
            isLoading: false
          }));

      }
    );
  };

  onValuesChange = (changedValues: any, allValues: any) => {
    const { password, confirm } = allValues;

    this.setState({
      hasErrors: password !== confirm || !_.isEmpty(validatePasswordStrength(password)),
    });
  };

  render = () => {
    const { match } = this.props;
    const { showTermsConditions, showGDPRConditions } = this.state;
    const showPasswordForm = match.params?.show_password_form === '1';

    return (
      <>
        <div className="ta-c mB-40">
          <h1 className="ta-c">Welcome to PACS</h1>
          <p>To get started, we need you to set up a password for your account.</p>
        </div>
        <Form
          layout="vertical"
          onFinish={ this.handleAcceptInvitation }
          onValuesChange={ this.onValuesChange }
        >
          { !!showPasswordForm ?
            <>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                  },
                  () => ({
                    validator(__, password) {
                      const errors = validatePasswordStrength(password);
                      if (_.isEmpty(errors)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(errors);
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords do not match');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="toc"
                label=""
                rules={[
                  {
                    required: true,
                    message: 'You must accept the Terms and Conditions',
                  },
                ]}
                hasFeedback
              >
                <Checkbox.Group>
                  <Checkbox value={ 'terms' } /><span className="mL-10">I agree to the <Link onClick={ () => this.setState({ showTermsConditions: true }) }>Terms and Conditions</Link></span>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                name="gdpr"
                label=""
                rules={[
                  {
                    required: true,
                    message: 'You must accept the GDPR Consent',
                  },
                ]}
                hasFeedback
              >
                <Checkbox.Group>
                  <Checkbox value={ 'gdpr' } /><span className="mL-10">I agree with the <Link onClick={ () => this.setState({ showGDPRConditions: true }) }>GDPR Consent</Link></span>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item>
                <div className="ta-c">
                  <Button
                    block
                    type="primary"
                    className="login-form-button"
                    htmlType="submit"
                    loading={ this.state.isLoading }
                    disabled={ this.state.isLoading || this.state.hasErrors }
                  >
                    Set Password
                  </Button>
                </div>
              </Form.Item>
            </>
          :
            <Form.Item>
              <div className="ta-c">
                <Button
                  block
                  type="primary"
                  className="login-form-button"
                  htmlType="submit"
                  disabled={ this.state.isLoading }
                  loading={ this.state.isLoading }
                >
                  Complete verification
                </Button>
              </div>
            </Form.Item>
          }

          <Form.Item>
            <div className="ta-c">
              <span>Already got an account?</span> <a href="/login">Login here</a>
            </div>
          </Form.Item>
        </Form>
        { showTermsConditions && <TermsModal type={ 'terms' } onClose={ () => this.setState({ showTermsConditions: false }) } /> }
        { showGDPRConditions && <TermsModal type={ 'gdpr' } onClose={ () => this.setState({ showGDPRConditions: false }) } /> }
      </>
    );
  };

};

export default withRouter(AcceptInvitation);