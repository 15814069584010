// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Checkbox, Form, Input, Modal, Popconfirm, Select, Tabs, InputNumber } from 'antd';
import BasicListView from 'components/basic-list';
import Badge, { BadgeType } from 'components/badge';

// Icons
import { EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// Utils
import { transformFieldTitle } from 'utils/workflow';
import { orderListByKey } from 'utils/formSetup';

// Interfaces
import { EntityField, Field, Entity as IEntity } from 'views/admin/entity/Entity.interfaces';

const { TextArea } = Input;

interface Props {
  deleteField: (field: EntityField) => void;
  editField: (fieldId: string, config: any, cb: () => void) => void;
  addField: (fieldId: string, group_id: number | undefined, config: any, cb: () => void) => void;
  entityFields: EntityField[];
  fields: Field[];
  formGroups: Array<{ label: string; options: Array<{ id: number; label: string }> }>;
  entity: IEntity | null;
  isLoading: boolean;
  isAddingField: boolean;
};

interface State {
  showEditFieldDialog: boolean;
  isEditingConfig: boolean;
  newField: Partial<Field>;
};

class FieldMappingTab extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    newField: {},
    showEditFieldDialog: false,
    isEditingConfig: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getFields = (): Array<{ label: string, options: Field[] }> => {
    const { fields, entityFields, entity } = this.props;
    const { isEditingConfig, newField } = this.state;

    if (isEditingConfig) {
      const selectedField = fields.find((field) => field.id === newField.id) as Field;
      return [{ label: selectedField.type, options: [selectedField] }];
    }

    const selectedFields = entityFields.filter((entityField) => entityField.entity_type === entity?.type);
    const filteredFields = fields.filter((field) => selectedFields.every((selectedField) => selectedField.field_id !== field.id));
    const types: string[] = orderListByKey(_.uniqBy(filteredFields, 'type'), 'type').map((item: Field) => item.type);

    return types.map((type) => {
      const items = filteredFields.filter((field) => field.type === type);
      return { label: type, options: orderListByKey(items, 'label') };
    });
  };

  getConfig = () => {
    const { config = {} } = this.state.newField;

    if (_.has(config, 'label') && !config.label?.trim()) {
      delete config.label;
    }
    if (_.has(config, 'tooltip') && !config.tooltip?.trim()) {
      delete config.tooltip;
    }

    return _.isEmpty(config) ? [] : config;
  };

  getPlaceholder = (key: 'label' | 'description') => {
    const { fields } = this.props;
    const { isEditingConfig, newField } = this.state;

    const placeholder = {
      label: 'Please enter a label',
      description: 'Please enter a tooltip'
    };

    if (isEditingConfig) {
      const field = fields.find(item => item.id === newField.id);
      placeholder.label = field?.label || '';
      placeholder.description = field?.description || '';
    }

    return placeholder[key];
  };

  renderAdvancedSettings = (field: Field | undefined, newField: Partial<Field>, entityFields: EntityField[]): React.ReactNode => {
    const entityField = entityFields.find((entityField: EntityField) => entityField.field_id === field?.id);
    if (!!field && !!entityField) {
      switch (field?.type) {
        case 'file':
          const allowedExtensions = field?.extensions ? field.extensions : [];
          const maxFileSize = field?.max_file_size ? field.max_file_size : 50;
          return (
            <>
              <Form.Item
                label="Allowed extensions"
                name="allowed_extensions"
                initialValue={ entityField?.config?.allowed_extensions }
                preserve={ false }
              >
                <Select
                  allowClear
                  mode={ 'multiple' }
                  style={ { width: '100%' } }
                  placeholder={ 'Leave empty to allow all file extensions' }
                  onChange={ (extensions: string[]) => {
                    this.setState({
                      newField: _.set(newField, 'config.allowed_extensions', extensions)
                    });
                  } }
                >
                  { allowedExtensions.map((extension: string) => (
                    <Select.Option key={ extension } value={ extension }>
                      { extension }
                    </Select.Option>
                  )) }
                </Select>
              </Form.Item>
              <Form.Item
                label="Max File Size"
                name="max_file_size"
                initialValue={ entityField?.config?.max_file_size ? (entityField?.config?.max_file_size / 1024 / 1024) : undefined }
                preserve={ false }
              >
                <InputNumber
                  style={ { width: '100%' } }
                  placeholder={ `${maxFileSize / 1024 / 1024}` }
                  addonAfter={ 'MB' }
                  onChange={ (fileSize: number) => {
                    this.setState({
                      newField: _.set(newField, 'config.max_file_size', (fileSize * 1024 * 1024))
                    });
                  } }
                />
              </Form.Item>
            </>
          );
        break;

        case 'kpi_priority':
          const prioritySets: any = field?.advanced_config && field?.advanced_config?.priority_sets ? field?.advanced_config?.priority_sets : [];
          return (
            <Form.Item
              label="KPI Priority Set"
              name="priority_set"
              initialValue={ entityField?.config?.priority_set }
              preserve={ false }
            >
              <Select
                allowClear
                style={ { width: '100%' } }
                placeholder={ 'Select priority set' }
                onChange={ (priority_set: string) => {
                  this.setState({
                    newField: _.set(newField, 'config.priority_set', priority_set)
                  });
                } }
              >
                { prioritySets.map((set: any) => (
                  <Select.Option key={ set.reference } value={ set.reference }>
                    { set.title }
                  </Select.Option>
                )) }
              </Select>
            </Form.Item>
          );
        break;
      }
    }

    return null;
  };

  renderListView = () => {
    const { entityFields, fields, entity, isLoading, deleteField } = this.props;
    const selectedFields: EntityField[] = entityFields.filter((entityField) => entityField.entity_type === entity?.type);

    const columns = [
      {
        key: 'label',
        dataIndex: 'label',
        title: 'Label',
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'type',
        dataIndex: 'type',
        title: 'Type',
        sorter: true,
        ellipsis: true,
        filterable: true,
      },
      {
        key: 'protected',
        dataIndex: 'protected',
        title: 'Protected',
        render: (protectedField: boolean) => (
          <Badge
            type={ protectedField ? BadgeType.Success : BadgeType.Disabled }
            text={ protectedField ? 'Protected' : 'Unprotected' }
          />
        ),
        sorter: false,
        ellipsis: true,
        filterable: false,
        width: 100,
      },
      {
        key: 'required',
        dataIndex: 'required',
        title: 'Required',
        render: (required: boolean) => (required ? 'Yes' : 'No'),
        sorter: true,
        ellipsis: true,
        filterable: true,
        width: 100,
      },
      {
        key: 'actions',
        dataIndex: 'actions',
        title: '',
        render: (__: any, field: EntityField) => {
          return (
            <>
              <EditOutlined
                className="link"
                style={{ fontSize: 18 }}
                onClick={ () => {
                  this.setState({
                    showEditFieldDialog: true,
                    isEditingConfig: true,
                    newField: { id: field.field_id, config: field.config },
                  });
                } }
              />
              { !field.protected && (
                <Popconfirm
                  title={ 'Are you sure?' }
                  icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
                  okButtonProps={{
                    danger: true
                  }}
                  placement="topRight"
                  onConfirm={ () => deleteField(field) }
                >
                  <DeleteOutlined
                    className="mL-20 link"
                    style={{ fontSize: 18 }}
                  />
                </Popconfirm>
              ) }
            </>
          );
        },
        width: 100,
        sorter: false,
        ellipsis: true,
        filterable: false,
        align: 'start'
      },
    ];

    const items = selectedFields.map((entityField: EntityField, index: number) => {
      return {
        'key': index,
        'field_id': entityField.field_id,
        'entity_type': entityField.entity_type,
        'entity_bundle': entityField.entity_bundle,
        'type': transformFieldTitle(entityField.type),
        'label': fields.find((field: Field) => field.id === entityField.field_id)?.label || entityField.entity_type,
        'config': entityField.config,
        'required': entityField.config.required || false,
        'protected': !!entityField.protected,
      };
    });

    return (
      <BasicListView
        rawData
        columns={ columns }
        items={ items }
        isLoading={ isLoading }
      />
    );
  };

  renderEditFieldDialog = () => {
    const { fields, entityFields, formGroups, isAddingField, addField, editField } = this.props;
    const { isEditingConfig, newField } = this.state;

    const onCancel = () => this.mounted && this.setState({
      showEditFieldDialog: false,
      isEditingConfig: false,
      newField: {},
    });;

    const shouldShowLockedOption: boolean = fields.find(item => item.id === newField.id)?.type !== 'calculated';
    const shouldShowGroupSelect: boolean = !isEditingConfig && !!formGroups.length;
    const advancedSettings = this.renderAdvancedSettings(fields.find((field) => field.id === newField.id), newField, entityFields);

    return (
      <Modal
        title={ isEditingConfig ? 'Edit Field' : 'Add Field' }
        closable={ false }
        maskClosable={ !isAddingField }
        centered
        visible
        onCancel={ onCancel }
        onOk={ () => {
          const { id, group_id } = newField;
          const config = this.getConfig();

          if (isEditingConfig) {
            editField(id as string, config, onCancel);
          } else {
            addField(id as string, group_id, config, onCancel);
          }
        } }
        okText= { 'Save' }
        okButtonProps={{
          disabled: isAddingField || !newField.id,
          loading: isAddingField,
        }}
        cancelButtonProps={{
          disabled: isAddingField,
        }}
      >
        <Tabs defaultActiveKey="basic" style={{ minHeight: 420 }}>
          <Tabs.TabPane tab="Basic Settings" key="basic">
            <Form layout="vertical">
              <Form.Item label="Field" required>
                <Select
                  showSearch
                  disabled={ isAddingField || isEditingConfig }
                  placeholder={ 'Select Field' }
                  value={ newField.id }
                  filterOption={ (input: any, optionOrGroup: any) => {
                    if (_.isArray(optionOrGroup.options)) {
                      return false;
                    }
                    return optionOrGroup.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  } }
                  onChange={ (value: string) => {
                    this.mounted && this.setState({
                      newField: Object.assign(newField, { id: value }),
                    });
                  } }
                >
                  { this.getFields().map((group: { label: string; options: Field[] }, index) => (
                    <Select.OptGroup label={ _.startCase(_.toLower(group.label)).replaceAll('_', ' ') } key={ `${group.label}_${index}` }>
                      { group.options.map((field: Field) => (
                        <Select.Option key={ field.id } value={ field.id }>{ field.label }</Select.Option>
                      )) }
                    </Select.OptGroup>
                  )) }
                </Select>
              </Form.Item>
              <Form.Item label="Label">
                <Input
                  value={ newField.config?.label }
                  placeholder={ this.getPlaceholder('label') }
                  onChange={ (e) => {
                    const config = { ...newField.config, label: e.target.value };
                    this.setState({
                      newField: Object.assign(newField, { config: config })
                    });
                  } }
                />
              </Form.Item>
              { shouldShowGroupSelect && (
                <Form.Item label="Group">
                  <Select
                    showSearch
                    disabled={ isAddingField }
                    placeholder={ 'Select Group' }
                    value={ newField.group_id }
                    filterOption={ (input: any, optionOrGroup: any) => {
                      if (_.isArray(optionOrGroup.options)) {
                        return false;
                      }
                      return optionOrGroup.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    } }
                    onChange={ (group_id: number) => {
                      this.mounted && this.setState({
                        newField: Object.assign(newField, { group_id: group_id }),
                      });
                    } }
                  >
                    { formGroups.map((tab, index) => (
                      <Select.OptGroup label={ tab.label } key={ `${tab.label}_${index}` }>
                        { tab.options.map((group) => (
                          <Select.Option key={ group.id } value={ group.id }>{ group.label }</Select.Option>
                        )) }
                      </Select.OptGroup>
                    )) }
                  </Select>
                </Form.Item>
              ) }
              <Form.Item label="Tooltip">
                <TextArea
                  rows={ 2 }
                  value={ newField.config?.description }
                  placeholder={ this.getPlaceholder('description') }
                  onChange={ (e) => {
                    const config = { ...newField.config, description: e.target.value };
                    this.setState({
                      newField: Object.assign(newField, { config: config })
                    });
                  } }
                />
              </Form.Item>
              <Form.Item>
                <Checkbox
                  onChange={ (e) => {
                    const config = { ...newField.config, required: e.target.checked };
                    this.setState({
                      newField: Object.assign(newField, { config: config })
                    });
                  } }
                  checked={ !!newField.config?.required }
                >
                  Required
                </Checkbox>
                { shouldShowLockedOption && (
                  <Checkbox
                    onChange={ (e) => {
                      const config = { ...newField.config, locked: e.target.checked };
                      this.setState({ newField: Object.assign(newField, { config: config }) });
                    } }
                    checked={ !!newField.config?.locked }
                  >
                    Locked
                  </Checkbox>
                ) }
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Advanced settings" key="advanced" disabled={ !advancedSettings }>
            { advancedSettings && (
              <Form layout="vertical">
                { advancedSettings }
              </Form>
            ) }
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    );
  };

  render = () => {
    const { showEditFieldDialog } = this.state;
    return (
      <>
        { this.renderListView() }
        { showEditFieldDialog && this.renderEditFieldDialog() }
      </>
    );
  };
};

export default FieldMappingTab;