// Libs
import React from 'react';
import _ from 'lodash';

// Components
import SpecificationCompanySummary from 'components/form/field/view/specification-company-summary/SpecificationCompanySummary';
import PurchaseOrders from 'components/form/field/view/purchase-orders/PurchaseOrders';
import Invoices from 'components/form/field/view/invoices/Invoices';
import AssetMaintenance from 'components/form/field/view/asset-maintenance/AssetMaintenance';
import { RestrictionWrapper, hasPermission } from 'components/restriction';

// Views
import DocumentView from 'views/common/DocumentView';
import CommentView from 'views/common/CommentView';
import ListView from 'views/common/ListView';
import VersionView from 'views/common/VersionView';
import AuditView from 'views/common/AuditView';
import ResourceView from 'views/common/ResourceView';
import ReportView from 'views/common/ReportView';
import CalendarView from 'components/calendar-view';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { FormElement } from 'components/form/form-wrapper';

interface Props {
  viewType: string | undefined;
  record: RecordFormEntity;
  clientId?: number;
  element: FormElement;
  getRecord?: (silent?: boolean) => void;
};

class ViewComponent extends React.Component<Props> {

  render = () => {
    const { record, element, getRecord, clientId } = this.props;

    const viewType = this.props.viewType;
    const bundle = _.kebabCase(record?.bundle);
    const type = _.kebabCase(record?.type);

    switch (viewType) {
      case 'document':
        return (
          <RestrictionWrapper restricted={ !hasPermission(record.permissions, `${_.snakeCase(record.bundle)}_${_.snakeCase(record.type)}_${_.snakeCase(viewType)}_view`) }>
            <DocumentView pure record={ record } entity={ bundle } type={ type } />
          </RestrictionWrapper>
        );
      case 'comment':
        return (
          <RestrictionWrapper restricted={ !hasPermission(record.permissions, `${_.snakeCase(record.bundle)}_${_.snakeCase(record.type)}_${_.snakeCase(viewType)}_view`) }>
            <CommentView pure record={ record } entity={ bundle } type={ type } />
          </RestrictionWrapper>
        );
      case 'version':
        return (
          <RestrictionWrapper restricted={ !hasPermission(record.permissions, `${_.snakeCase(record.bundle)}_${_.snakeCase(record.type)}_${_.snakeCase(viewType)}_view`) }>
            <VersionView pure record={ record } entity={ bundle } type={ type } />
          </RestrictionWrapper>
        );
      case 'audit':
        return (
          <RestrictionWrapper restricted={ !hasPermission(record.permissions, `${_.snakeCase(record.bundle)}_${_.snakeCase(record.type)}_${_.snakeCase(viewType)}_view`) }>
            <AuditView pure record={ record } entity={ bundle } type={ type } />
          </RestrictionWrapper>
        );
      case 'resource':
        return (
          <RestrictionWrapper restricted={ !hasPermission(record.permissions, `${_.snakeCase(record.bundle)}_${_.snakeCase(record.type)}_${_.snakeCase(viewType)}_view`) }>
            <ResourceView pure record={ record } entity={ bundle } type={ type } getRecord={ (silent?: boolean) => getRecord?.(silent) } />
          </RestrictionWrapper>
        );
      case 'report':
        return <ReportView pure config={ element?.config } record={ record } entity={ bundle } type={ type } />;
      case 'po_listing':
        return <PurchaseOrders pure config={ element?.config } record={ record } entity={ bundle } type={ type } />;
      case 'invoice_listing':
        return <Invoices pure config={ element?.config } record={ record } entity={ bundle } type={ type } />;
      case 'specification_company_summary':
        return <SpecificationCompanySummary record={ record } bundle={ bundle } type={ type } permissions={ record.permissions } />;
      case 'asset_maintenance_tree':
        return <AssetMaintenance record={ record } />;
      case 'project_milestones':
      case 'sublease_summary':
        return (
          <ListView
            record={ record }
            customEndpoint={ `${bundle}/${type}/${record.id}/${viewType.replaceAll('_', '-')}` }
          />
        );
      case 'audit_calendar_view_via_property':
        const auditOrigin = { id: record.id, bundle: bundle, type: type };
        return (
          <CalendarView client_id={ clientId } origin={ auditOrigin } customEndpoint={ 'audit' } />
        );
      case 'action_calendar_view_via_property':
        const actionOrigin = { id: record.id, bundle: bundle, type: type };
        return (
          <CalendarView client_id={ clientId } origin={ actionOrigin } customEndpoint={ 'action' } />
        );
      case 'ppm_calendar_view_via_asset':
        const ppmOrigin = { id: record.id, bundle: bundle, type: type };
        return (
          <CalendarView client_id={ clientId } origin={ ppmOrigin } customEndpoint={ 'help-desk-ticket' } />
        );
      default:
        return <>View not found</>;
    }
  };
};

export default ViewComponent;