// Libs
import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Empty } from "antd";
import { Bar, Column, Pie } from '@ant-design/plots';
import { Pagination, Select } from 'antd';

// Services
import { getFormatedDate, getFormatedNumber, getUserSetting } from 'services/settings';

// Utils
import { isHex, isNumeric, convertToAbbreviateNumber } from 'utils/utils';

// Interfaces
import { ColumnType, IColumn, IPreview, IData } from 'components/insight/Insight.interfaces';

interface Props {
  mode: 'INSIGHT' | 'DASHBOARD' | 'WIDGET';
  insight: IPreview;
  height?: number;
};

interface State {
  currentPage: number;
  itemsPerPage: number;
};

class Chart extends React.Component<Props> {

  state: State = {
    currentPage: 1,
    itemsPerPage: 5,
  };

  shouldComponentUpdate = (nextProps: Props, nextState: State) => {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  };

  paginageItems = (data: IData[], currentPage = 1, itemsPerPage = 5) => {
    return _.drop(data, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  renderControls = (currentPage: number, itemsPerPage: number, data: IData) => {
    return (
      <div>
        <div
          className="d-f jc-sb ai-c mB-10 mT-10"
          style={{ userSelect: 'none' }}
        >
          <div className="d-if">
            <span>{ 'Show' }</span>
            <span className="mL-10 mR-10">
              <Select
                disabled={ _.isEmpty(data) }
                size={ 'small' }
                onChange={ (value: number) => {
                  this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                } }
                defaultValue={ itemsPerPage }
              >
                <Select.Option value={ 5 }>5</Select.Option>
                <Select.Option value={ 10 }>10</Select.Option>
                <Select.Option value={ 20 }>20</Select.Option>
              </Select>
            </span>
            <span>Entries of <b>{ data.length || 0 }</b></span>
          </div>
          <div className='d-if'>
            <Pagination
              simple
              disabled={ data.length === 0 }
              showSizeChanger={ false }
              current={ currentPage }
              total={ data.length }
              pageSize={ itemsPerPage }
              onChange={ page => {
                this.setState({
                  currentPage: page
                });
              } }
            />
          </div>
        </div>
      </div>
    );
  };

  renderChart = (insight: IPreview) => {

    const chartType = _.toUpper(insight?.chart?.type);

    let data = insight.data.map((row: any) => {

      if (chartType === 'PIE') {

        const valueInsightColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config.value_field);

        const key = insight.chart?.config.key_field as string;
        const value = insight.chart?.config.value_field as string;
        const keyKey = row[key];
        const valueValue = row[value];

        return {
          ...row,
          [key]: keyKey,
          [value]: valueInsightColumn?.type === 'number' ? parseFloat(valueValue) : valueValue,
        };
      }

      const xColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config.x_field);
      const yColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config.y_field);

      const key = insight.chart?.config.x_field as string;
      const value = insight.chart?.config.y_field as string;
      const keyKey = row[key];
      const valueValue = row[value];

      return {
        ...row,
        [key]: xColumn?.type === 'number' ? parseFloat(`${keyKey}`) : keyKey !== 'null' ? keyKey : '-',
        [value]: yColumn?.type === 'number' ? parseFloat(`${valueValue}`) : valueValue,
      };
    });

    data = chartType !== 'PIE' ? this.paginageItems(data, this.state.currentPage, this.state.itemsPerPage) : data;

    const config: any = {
      xField: insight.chart?.config.x_field,
      yField: insight.chart?.config.y_field,
      meta: {
        [insight?.chart?.config.x_field as string]: {
          alias: insight?.chart?.config.x_label
        },
        [insight?.chart?.config.y_field as string]: {
          alias: insight?.chart?.config.y_label
        },
      },
      xAxis: {
        title: {
          text: null
        },
        label: {
          autoHide: false,
          autoRotate: true,
          formatter: (label: string) => {
            return _.truncate(label, { 'length': 25, 'separator': /,? +/ });
          }
        },
      },
      yAxis: {
        title: {
          text: null
        },
        label: {
          autoHide: false,
          autoRotate: true,
          formatter: (label: string) => {
            return _.truncate(label, { 'length': 25, 'separator': /,? +/ });
          }
        },
      },
      legend: false,
      appendPadding: 40,
      data: data
    };

    // Show legend
    if (!!insight?.chart?.config?.show_legend) {
      switch (chartType) {
        case 'COLUMN':
          config.seriesField = insight.chart?.config.x_field;
        break;
        case 'BAR':
          config.seriesField = insight.chart?.config.y_field;
        break;
        case 'PIE':
          config.seriesField = insight.chart?.config.x_field;
        break;
      }
      config.legend = true;
    }

    switch (chartType) {
      case 'COLUMN':
      case 'BAR':

        const xColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config.x_field);
        const yColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config.y_field);

        if (!!xColumn?.config?.mode && ['year', 'date', 'datetime'].includes(xColumn?.config?.mode)) {
          config.xAxis.label.formatter = (label: string) => {
            return !!label && moment(label).isValid() ? getFormatedDate(label, getUserSetting('date_format'), false) : label;
          };
        }

        if (xColumn?.type === 'number' || xColumn?.column_type === ColumnType.Formula) {
          config.xAxis.label.formatter = (label: string) => {
            return !!label && isNumeric(label) ? getFormatedNumber(label) : label;
          };
        }

        if (!!yColumn?.config?.mode && ['year', 'date', 'datetime'].includes(yColumn?.config?.mode)) {
          config.yAxis.label.formatter = (label: string) => {
            return !!label && moment(label).isValid() ? getFormatedDate(label, getUserSetting('date_format'), false) : label;
          };
        }

        if (yColumn?.type === 'number' || yColumn?.column_type === ColumnType.Formula) {
          config.yAxis.label.formatter = (label: string) => {
            return !!label && isNumeric(label) ? getFormatedNumber(label) : label;
          };
        }

        if (!!insight.chart?.config.x_label) {
          config.xAxis.title.text = xColumn?.settings?.custom_title || insight.chart?.config.x_label;
        }

        if (!!insight.chart?.config.y_label) {
          config.yAxis.title.text = yColumn?.settings?.custom_title || insight.chart?.config.y_label;
        }

        config.label = {
          position: 'outer',
          content: (field: { [key: string]: string }) => {
            const value = field[insight.chart?.config.y_field as string];
            const shouldConvert = false; // Turned off for now

            if (yColumn?.type === 'number' || yColumn?.column_type === ColumnType.Formula) {
              if (!!value && isNumeric(value)) {
                if (shouldConvert && Number(value) > 1000) {
                  return convertToAbbreviateNumber(Number(value));
                }
                return getFormatedNumber(value);
              }
            }

            return value;
          },
        };

        config.tooltip = {
          title: chartType === 'BAR' ? config.yAxis.title.text : config.xAxis.title.text,
          customItems: (record: any) => {
            return !_.isEmpty(record) && record[0]?.name ? record : false;
          },
          formatter: (record: any) => {
            let formattedName = record[insight.chart?.config.x_field as string];
            let formattedValue = record[insight.chart?.config.y_field as string];

            if (insight.chart?.config?.group_field) {
              formattedName = record[insight.chart?.config?.group_field as string];
            }

            if ((xColumn?.type === 'number' || xColumn?.column_type === ColumnType.Formula) && isNumeric(formattedName)) {
              formattedName = getFormatedNumber(formattedName);
            }

            if ((yColumn?.type === 'number' || yColumn?.column_type === ColumnType.Formula) && isNumeric(formattedValue)) {
              formattedValue = getFormatedNumber(formattedValue);
            }

            switch (xColumn?.config?.mode) {
              case 'year':
                formattedName = getFormatedDate(formattedName, 'YYYY', false);
              break;
              case 'date':
                formattedName = getFormatedDate(formattedName, getUserSetting('date_format'), false);
              break;
              case 'datetime':
                formattedName = getFormatedDate(formattedName, getUserSetting('date_format'), true);
              break;
            }

            switch (yColumn?.config?.mode) {
              case 'year':
                formattedValue = getFormatedDate(formattedValue, 'YYYY', false);
              break;
              case 'date':
                formattedValue = getFormatedDate(formattedValue, getUserSetting('date_format'), false);
              break;
              case 'datetime':
                formattedValue = getFormatedDate(formattedValue, getUserSetting('date_format'), true);
              break;
            }

            return {
              name: formattedName,
              value: formattedValue || '-'
            };
          },
        };

        if (!!insight.chart?.config.color_field) {

          // Create map of grouping --> color
          // Only required if group AND color are both set
          const colorMap: any = [];

          if (!!insight.chart?.config.group_field && !_.isEmpty(config.data)) {
            config.data.forEach((row: any) => {
              // Fetch group/color pairs from the row
              const groupRef = row[insight.chart?.config.group_field as string];
              const colorRef = row[insight.chart?.config.color_field as string];

              // Add to color map
              if (!!groupRef && !!colorRef) {
                colorMap[groupRef] = colorRef;
              }
            });
          } else {
            // Mark colour as seriesField if no group defined
            config.seriesField = insight.chart?.config.color_field;
          }

          config.colorField = insight.chart?.config.color_field;
          config.color = (field: { [key: string]: string }) => {

            // Get first available value
            // ANTD Charts will only include the one group value
            const chartGroup: string | null = !_.isEmpty(field) && !!Object.values(field)[0] ? String(Object.values(field)[0]) : null;

            // Use color map to determine color if set
            if (chartGroup && !!colorMap[chartGroup]) {
              return colorMap[chartGroup];
            }

            // Otherwise return color value directly if it's a hex. If not use default
            return chartGroup && isHex(chartGroup) ? chartGroup : '#4684f4';
          };
        }

        if (chartType === 'COLUMN') {

          if (!!insight.chart?.config.group_field) {

            if (!!insight.chart?.config.group_mode) {
              switch (_.upperCase(insight.chart?.config.group_mode)) {
                case 'STACK':
                  config.isStack = true;
                break;
                case 'GROUP':
                  config.isGroup = true;
                break;
                case 'PERCENT':
                  config.isStack = true;
                  config.isPercent = true;
                break;
              }
            }

            config.seriesField = insight.chart?.config.group_field;
          }

          return <Column { ...config } />;
        }

        return <Bar { ...config } />;
      case 'PIE':

        const keyInsightColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config?.key_field);
        const valueInsightColumn = insight.columns.find((column: IColumn) => column.id === insight.chart?.config.value_field);

        config.colorField = insight.chart?.config.key_field;
        config.angleField = insight.chart?.config.value_field;

        config.label = {
          type: 'outer',
          content: (field: { [key: string]: string }) => {
            const value = field[insight.chart?.config?.key_field as string];

            if (keyInsightColumn?.type === 'number' || keyInsightColumn?.column_type === ColumnType.Formula) {
              return getFormatedNumber(value);
            }

            return value;
          },
        };

        if (insight.chart?.config?.color_field) {

          // Not available for colored fields
          config.legend = false;

          config.colorField = insight.chart?.config?.color_field;

          config.pieStyle = (field: { [key: string]: string }) => {
            const hex = String(field[insight.chart?.config?.color_field as string]);
            if (isHex(hex)) {
              return {
                fill: hex
              };
            }
          };
        }

        config.tooltip = {
          fields: [insight.chart?.config.key_field as string, insight.chart?.config.value_field as string],
          formatter: (record: any) => {
            let name = record[insight.chart?.config.key_field as string];
            let value = record[insight.chart?.config.value_field as string];

            if (keyInsightColumn?.type === 'number' || keyInsightColumn?.column_type === ColumnType.Formula) {
              name = getFormatedNumber(value);
            }

            if (valueInsightColumn?.type === 'number' || valueInsightColumn?.column_type === ColumnType.Formula) {
              value = getFormatedNumber(value);
            }

            return { name: name, value: value };
          },
        };

        return <Pie { ...config } />;
      default:
        return <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />;
    }
  };

  render = () => {
    const { mode, insight, height } = this.props;
    const { currentPage, itemsPerPage } = this.state;
    return (
      <div className='w-100p' style={{ height: height || '90%', maxHeight: mode === 'INSIGHT' ? 400 : 'inherit' }}>
        { insight.chart?.type && ['BAR', 'COLUMN'].includes(insight.chart?.type) && this.renderControls(currentPage, itemsPerPage, insight.data) }
        <div className="w-100p h-100p bg-white bdB">
          { this.renderChart(insight) }
        </div>
      </div>
    );
  };
};

export default Chart;
