// Libs
import * as React from 'react';
import _ from 'lodash';

// Components
import Badge, { BadgeSize, BadgeType } from 'components/badge';
import BlockingSpinner from 'components/blocking-spinner';
import BasicList from "components/basic-list";
import Jumbotron from 'components/jumbotron';

// Utils
import { timeConvert } from 'utils/utils';

// Interfaces
import { KpiRecord, KpiRecordStatus } from './KpiLibrary.interfaces';

// Styles
import './KpiLibrary.scss';

interface Props {
  kpis: KpiRecord[];
  isFetching: boolean;
};

interface State {};

class KpiList extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {};

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderMeasure = (measure: KpiRecord['measure']): JSX.Element => {
    if (_.has(measure, 'minutes')) {
      const { hours, minutes } = timeConvert(measure.minutes);
      return (
        <span>{ `${hours}h ${minutes}min` }</span>
      );
    }

    if (_.has(measure, 'from') && _.has(measure, 'to')) {
      return (
        <>
          <span className="mR-20">From: { measure.from }%</span>
          <span>To: { measure.to }%</span>
        </>
      );
    }

    return <span>-</span>;
  };

  renderStatus = (status: KpiRecordStatus): JSX.Element => {
    switch (status) {
      case KpiRecordStatus.Pass:
        return <Badge type={ BadgeType.Success } text={ 'Pass' } size={ BadgeSize.Large } />;

      case KpiRecordStatus.Pending:
        return <Badge type={ BadgeType.Disabled } text={ 'Pending' } size={ BadgeSize.Large } />;

      case KpiRecordStatus.Fail:
        return <Badge type={ BadgeType.Danger } text={ 'Fail' } size={ BadgeSize.Large } />;
    }

    return <span>-</span>;
  };

  renderList = (kpi: KpiRecord[]): JSX.Element => {
    const { kpis } = this.props;

    const columns = [
      {
        key: 'title',
        dataIndex: 'title',
        title: 'Title',
        render: (title: string) => {
          return <span>{ title || '-' }</span>;
        },
        sorter: true,
        filterable: true,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status: KpiRecordStatus) => {
          return this.renderStatus(status);
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'priority',
        dataIndex: 'priority',
        title: 'Priority',
        render: (priority: string) => {
          return <span>{ priority || '-' }</span>;
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'measure',
        dataIndex: 'measure',
        title: 'Measure',
        render: (measure: KpiRecord['measure']) => {
          return this.renderMeasure(measure);
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
      {
        key: 'description',
        dataIndex: 'description',
        title: 'Description',
        render: (description: string) => {
          return description;
        },
        sorter: true,
        filterable: false,
        ellipsis: true,
        width: 120,
      },
    ];

    const mapData = (kpis: KpiRecord[]) => {
      return kpis.map((kpiRecord: KpiRecord, index: number) => {
        return {
          'key': index,
          'id': kpiRecord.id,
          'title': kpiRecord.title,
          'status': kpiRecord.status,
          'priority': kpiRecord.priority,
          'measure': kpiRecord.measure,
          'description': kpiRecord.description
        };
      });
    };

    return <BasicList columns={ columns } items={ mapData(kpis) } rightActions={[]} rawData/>;
  };

  render = (): JSX.Element => {
    const { kpis, isFetching } = this.props;

    return (
      <BlockingSpinner isLoading={ isFetching }>
        { this.renderList(kpis) }
      </BlockingSpinner>
    );
  };
};

export default KpiList;
