// Libs
import * as React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// Views
import KpiLibrary from 'views/workplace-service/KpiLibrary';
import KpiMetricListView from 'views/workplace-service/kpi-library/KpiMetricListView';
import KpiMetricRecord from 'views/workplace-service/kpi-library/KpiMetricRecord';
import PrioritySet from 'views/workplace-service/kpi-library/PrioritySet';
import NotFound from 'views/NotFound';

// Components
import { RestrictionWrapper, RestrictionHoC, hasPermission } from 'components/restriction';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserPermissions } from 'types/permissions';

// Styles
import 'assets/styles/_layout.scss';

interface Props {
  client_id: number;
  permissions: UserPermissions;
};

class KpiLibraryContainer extends React.Component<Props> {

  render = () => {
    const { client_id } = this.props;

    return (
      <Switch>

        <Route exact path="/workplace-services/kpi-library" component={ KpiLibrary } />

        <Route exact path="/workplace-services/kpi-library/metric" render={ (routeProps: RouteComponentProps | any) => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(this.props.permissions, 'kpi_metric_set_view_list') }>
              <KpiMetricListView />
            </RestrictionWrapper>
          );
        } } />

        <Route exact path="/workplace-services/kpi-library/metric/:kpi_metric_id" render={ (routeProps: RouteComponentProps | any) => {
          return (
            <RestrictionWrapper restricted={ !hasPermission(this.props.permissions, 'kpi_metric_view') }>
              <KpiMetricRecord id={ routeProps.match.params.kpi_metric_id } />
            </RestrictionWrapper>
          );
        } } />

        <Route exact path="/workplace-services/kpi-library/priority-set/:priority_set_id" component={ PrioritySet } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };

};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    permissions: store.UserState.user.permissions,
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(RestrictionHoC(KpiLibraryContainer, 'kpi_library_manage'));
