// Libs
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Table, Tooltip, Select, Pagination } from 'antd';
import NumberFormat from 'react-number-format';

// Services
import { getNumberFormatProps, getFormatedDate, getUserSetting } from 'services/settings';
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import { isNumeric, nestedSet } from 'utils/utils';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

// Interfaces
import { ColumnType, IColumn, IData, IPreview } from 'components/insight/Insight.interfaces';
import { IWidget } from 'components/widget/Widget.interface';

interface Props {
  mode: 'INSIGHT' | 'DASHBOARD' | 'WIDGET';
  insight: Partial<IPreview>;
  widget?: IWidget;
  isLoading: boolean;
  forceHeight?: number;
  clientId?: number;
};

interface State {
  tableHeight: number;
  currentPage: number;
  itemsPerPage: number;
  sorter: any;
};

const API: Api = new Api();

class ChartTable extends React.Component<Props, State> {

  state: State = {
    tableHeight: 500,
    currentPage: 1,
    itemsPerPage: 50,
    sorter: null,
  };

  componentDidMount = () => {
    this.heightObserver(this.props?.widget?.key);
  };

  componentDidUpdate = () => {
    this.heightObserver(this.props?.widget?.key);
  };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  };

  heightObserver = (widgetKey: string | number | undefined) => {
    let tableHeight: number = 0;

    if (widgetKey) {
      const widgetHeight: number = document.getElementById(`widget_key_${widgetKey}`)?.offsetHeight || 0;
      tableHeight = widgetHeight - 110;
    } else {
      const root: number = document.getElementById('root')?.offsetHeight || 0;
      const header: number = document.getElementById('Header')?.offsetHeight || 0;
      const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
      const tabViewBar = document.querySelector('#Insight-wrapper #TabView-bar') !== null ? (document.querySelector('#Insight-wrapper #TabView-bar') as HTMLElement).offsetHeight : 0;
      const filtersHeight: number = document.getElementById('Insight-filters')?.offsetHeight || 0;
      tableHeight = root - (header + jumbotron + tabViewBar + filtersHeight + 170);
    }

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }
  };

  paginageItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  calculateTemplateColumnTotals = (column: IColumn, rows: any) => {
    return rows.reduce((acc: any, row: any) => {
      return !!column.settings.show_total ? parseFloat(acc) + parseFloat(row[column.id] || 0) : 0;
    }, 0);
  };

  sortData = (sorter: any, rows: any[]) => {

    const order = sorter.order === 'descend' ? 'desc' : 'asc';

    switch (sorter?.column?.type) {
      case 'string':
        return _.orderBy(rows, sorter.field, order);
      case 'number':
        return _.orderBy(rows, row => _.has(row, sorter?.field) ? parseFloat(row[sorter.field]) : -1, order);
      case 'datetime':
        return _.orderBy(rows, (row: any) => {
          if (_.has(row, sorter?.field)) {
            return moment(row[sorter.field], 'YYYY-MM-DD HH:mm:ss').valueOf();
          } else {
            return '-';
          }
        }, order);
      default:
        return _.orderBy(rows, sorter.field, order);
    }
  };

  downloadDocument = async (downloadDocumentsEndpoint: string, filename: string): Promise<void> => {
    try {
      await API.download(downloadDocumentsEndpoint, filename);
    } catch (error) {
      console.error(error);
      Notification('error', `Failed to download`);
    }
  };

  renderSummary = (columns: IColumn[], values: any) => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          { columns
            .filter((column: IColumn) => {
              return !column?.settings?.hidden;
            })
            .map((column: IColumn, index: number) => this.renderSummaryCell(index, column, values))
          }
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  renderSummaryCell = (index: number, column: IColumn, values: any) => {
    return (
      <Table.Summary.Cell key={ index } index={ index } colSpan={ 1 }>
        <div className='ta-r fw-600'>
          <NumberFormat
            { ...getNumberFormatProps() }
            fixedDecimalScale
            decimalScale={ column?.type === 'string' ? 0 : 2 }
            value={ !!column?.settings?.show_total ? this.calculateTemplateColumnTotals(column, values) : '-' }
            displayType={ 'text' }
          />
        </div>
      </Table.Summary.Cell>
    );
  };

  renderControls = (
    columns: IColumn[],
    dataSource: any[],
    itemsPerPage: number,
    currentPage: number,
  ) => {
    return (
      <div>
        <div
          className="d-f jc-sb ai-c mB-10 mT-10"
          style={{ userSelect: 'none' }}
        >
          <div className="d-if">
            <span>{ 'Show' }</span>
            <span className="mL-10 mR-10">
              <Select
                disabled={ (_.isEmpty(columns) || _.isEmpty(dataSource)) }
                size={ 'small' }
                onChange={ (value: number) => {
                  this.setState({
                    currentPage: 1,
                    itemsPerPage: value
                  });
                } }
                defaultValue={ itemsPerPage }
              >
                <Select.Option value={ 25 }>25</Select.Option>
                <Select.Option value={ 50 }>50</Select.Option>
                <Select.Option value={ 100 }>100</Select.Option>
              </Select>
            </span>
            <span>Entries of <b>{ dataSource.length || 0 }</b></span>
          </div>
          <div className="d-if">
            <Pagination
              simple
              disabled={ _.isEmpty(dataSource) }
              showSizeChanger={ false }
              current={ currentPage }
              total={ dataSource.length }
              pageSize={ itemsPerPage }
              onChange={ (page: number) => {
                this.setState({
                  currentPage: page
                });
              } }
            />
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const { clientId, insight, forceHeight, isLoading } = this.props;
    const { tableHeight, sorter, itemsPerPage, currentPage } = this.state;

    const columns: IColumn[] = !!insight?.columns ? insight?.columns : [];
    const showTotal = insight?.columns && !_.isEmpty(columns) ? insight?.columns.some((column: IColumn) => !!column?.settings?.show_total) : false;
    let dataSource = !_.isEmpty(insight?.data) ? nestedSet(insight?.data) : [];

    if (!_.isEmpty(sorter)) {
      dataSource = this.sortData(sorter, dataSource);
    }

    return (
      <div>
        <div>
          { insight && this.renderControls(columns, dataSource, itemsPerPage, currentPage) }
        </div>
        <div>
          <Table
            sticky
            size={ 'small' }
            loading={ isLoading }
            bordered
            showHeader={ !_.isEmpty(insight?.columns) }
            scroll={{
              y: forceHeight || tableHeight,
              x: columns.reduce((acc: number, curr: IColumn) => acc += curr.config?.width || 200, 0),
            }}
            columns={ columns
              .filter((column: IColumn) => {
                return !column?.settings?.hidden;
              })
              .map((column: IColumn, index: number) => {
                const props: any = {};

                // Width
                if (!!column?.settings?.width) {
                  props.width = column.settings.width;
                }

                // Wrap text
                if (column?.settings?.wrap_text === false) {
                  props.ellipsis = true;
                }

                return {
                  ...props,
                  key: index,
                  dataIndex: column.id,
                  type: column.type,
                  sorter: ['string', 'number', 'datetime'].includes(column.type),
                  title: () => {

                    let title: React.ReactNode = !!column?.settings?.custom_title ? column.settings.custom_title : column.title;

                    if (!!column?.settings?.tooltip) {
                      title = (
                        <>
                          <span>{ title }</span>
                          <Tooltip
                            className="mL-5"
                            placement="top"
                            title={ column.settings.tooltip }
                          >
                            <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                          </Tooltip>
                        </>
                      );
                    }

                    return (
                      <div className={ classNames({ 'ta-r': column.type === 'number' }) }>
                        { title }
                      </div>
                    );
                  },
                  render: (__: any, row: IData) => {

                    const value = !_.isNil(row[column.id]) ? row[column.id] : null;

                    const numberConfig = getNumberFormatProps();

                    switch (column?.type) {
                      case 'string':
                        if (column.column_type === ColumnType.Formula && isNumeric(value)) {
                          return (
                            <div className='ta-r'>
                              <NumberFormat
                                { ...numberConfig }
                                displayType={ 'text' }
                                value={ !_.isNull(value) ? value : '-' }
                              />
                            </div>
                          );
                        }

                        if (column.config?.is_downloadable) {
                          let filename: string = uuidv4();
                          let fileId: string | null = null;

                          if (!!column.config?.filename_column_id && !_.isNil(row[column.config.filename_column_id])) {
                            filename = row[column.config.filename_column_id];
                          }

                          if (!!column.config?.file_id_column_id && !_.isNil(row[column.config.file_id_column_id])) {
                            fileId = row[column.config.file_id_column_id];
                          }

                          if (fileId) {
                            return (
                              <a
                                className='d-f primaryColor'
                                onClick={ () => this.downloadDocument(`client/${clientId}/file/document/${fileId}/download`, filename) }
                              >
                                { value }
                              </a>
                            );
                          }
                        }

                        return (
                          <span>{ value || '-' }</span>
                        );
                      case 'number':
                        if (_.has(column, 'config.apply_number_format') && !column.config.apply_number_format) {
                          return (
                            <div className='ta-r'>
                              { !_.isNull(value) ? value : '-' }
                            </div>
                          );
                        }

                        if (_.has(column, 'config.decimal')) {
                          numberConfig.decimal = column.config.decimal;
                          numberConfig.fixedDecimalScale = !!column.config.decimal;
                        }

                        return (
                          <div className='ta-r'>
                            <NumberFormat
                              { ...numberConfig }
                              displayType={ 'text' }
                              value={ !_.isNull(value) ? value : '-' }
                            />
                          </div>
                        );
                      case 'datetime':

                        if (!value) return '-';

                        switch (column?.config?.mode) {
                          case 'date':
                            return getFormatedDate(value, getUserSetting('date_format'), false);
                          case 'year':
                            return getFormatedDate(value, 'YYYY', false);
                          default:
                            return getFormatedDate(value, getUserSetting('date_format'), true);
                        }
                      default:
                        return '-';
                    }
                  },
                };
              })
            }
            onChange={ (__, ___, sorter: any, ____) => {
              this.setState({
                sorter: sorter
              });
            } }
            showSorterTooltip={ false }
            dataSource={ this.paginageItems(dataSource, currentPage, itemsPerPage) }
            pagination={ false }
            summary={ () => showTotal ? this.renderSummary(insight?.columns || [], insight?.data || []) : false }
          />
        </div>
      </div>
    );
  };
};

export default ChartTable;
