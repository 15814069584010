// Libs
import * as React from 'react';
import { connect } from 'react-redux';

// Components
import KpiMetricForm from 'components/kpi-library/KpiMetricForm';

// Actions
import {
  setBreadcrumbsLoading,
  setBreadcrumbs,
  setSecondarySidebarRoutes,
} from 'store/UI/ActionCreators';

// Interfaces
import AppState from 'store/AppState.interface';
import { KpiMetric, KpiMetricType, KpiPrioritySet } from 'components/kpi-library/KpiLibrary.interfaces';
import { Breadcrumb } from 'store/UI/State.interface';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import history from 'utils/history';

const API: Api = new Api();

interface Props {
  id: number;
  client_id: number | undefined;
  setBreadcrumbsLoading(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

interface State {
  kpiMetric: KpiMetric | null;
  kpiMetricTypes: KpiMetricType[];
  kpiPrioritySets: KpiPrioritySet[]
  isFetching: boolean;
  isModifying: boolean;
  isPublishing: boolean;
  isVersioning: boolean;
};

class KpiMetricRecord extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    kpiMetric: null,
    kpiMetricTypes: [],
    kpiPrioritySets: [],
    isFetching: false,
    isModifying: false,
    isPublishing: false,
    isVersioning: false,
  };

  componentDidMount = async () => {
    const { client_id, id } = this.props;

    this.mounted = true;

    try {
      this.props.setBreadcrumbsLoading(true);

      await new Promise((resolve) => this.setState({isFetching: true}, () => resolve(null)));
      const response = await API.get(`client/${client_id}/kpi-library/metric/${id}`);

      this.props.setBreadcrumbs([
        { title: 'Home', path: '/' },
        { title: 'Workplace Services', path: '/workplace-services' },
        { title: 'KPI Library', path: '/workplace-services/kpi-library' },
        { title: response.kpi_metric.title || '', path: null }
      ], false);

      this.mounted && this.setState({
        kpiMetric: response.kpi_metric,
        kpiMetricTypes: response.kpiMetricTypes,
        kpiPrioritySets: response.kpiPrioritySets
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.props.setBreadcrumbsLoading(false);
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  onMetricCreateVersion = async (kpiMetricId: number) => {
    const { client_id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isVersioning: true }, () => resolve(null)));

      const newMetricVersion = await API.put(`client/${client_id}/kpi-library/metric/${kpiMetricId}/version`);

      Notification('success', 'New metric version has been created');

      history.push(`/workplace-services/kpi-library/metric/${newMetricVersion.id}`);
    } catch (error) {
      Notification('error', 'Failed to create new metric version', 'Failed');
    } finally {
      this.mounted && this.setState({
        isVersioning: false,
      });
    }
  };

  onEdit = async (metricId: KpiMetric['id'], payload: any, callback?: () => void): Promise<void> => {
    const { client_id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isModifying: true }, () => resolve(null)));

      const editedMetric = await API.put(`client/${client_id}/kpi-library/metric/${metricId}`, {
        data: payload,
      });

      this.mounted && this.setState({ kpiMetric: editedMetric });
    } catch(error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({ isModifying: false }, callback);
    }
  };

  onMetricPublish = async (kpiMetricId: number) => {
    const { client_id } = this.props;

    try {
      await new Promise((resolve) => this.setState({ isPublishing: true }, () => resolve(null)));

      const publishedKpiMetric = await API.put(`client/${client_id}/kpi-library/metric/${kpiMetricId}/publish`);

      this.mounted && this.setState({ kpiMetric: publishedKpiMetric });

      Notification('success', 'Kpi metric has been published', 'Published');
    } catch (error) {
      Notification('error', 'Failed to publish', 'Failed');
    } finally {
      this.setState({
        isPublishing: false,
      });
    }
  };

  render = () => {
    const { client_id } = this.props;
    const { isModifying, isPublishing, isVersioning, isFetching, kpiMetric, kpiMetricTypes, kpiPrioritySets } = this.state;

    return (
      <KpiMetricForm
        clientId={ client_id }
        kpiMetric={ kpiMetric }
        kpiMetricTypes={ kpiMetricTypes }
        kpiPrioritySets={ kpiPrioritySets }
        canEdit
        isModifying={ isModifying }
        onEdit={ this.onEdit }
        onPublish={ this.onMetricPublish }
        onVersion={ this.onMetricCreateVersion }
        isLoading={ isFetching }
        isPublishing={ isPublishing }
        isVersioning={ isVersioning }
      />
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbsLoading: (value: boolean) => dispatch(setBreadcrumbsLoading(value)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setSecondarySidebarRoutes: (routes: any[]) => dispatch(setSecondarySidebarRoutes(routes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KpiMetricRecord);
