// Libs
import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Select, Table, Input } from 'antd';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import { RecordFormEntity } from 'types/entities';

export enum Alignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
};

interface IThresholdType {
  id: string,
  title: string;
  value_required: boolean;
};

interface IThresholdHierarchy {
  id: string,
  title: string;
  record_types: IThresholdHierarchyRecordType[];
};

interface IThresholdHierarchyRecordType {
  target_type: string,
  target_bundle: string,
  title: string,
};

interface IField extends FormField {
  threshold_hierarchy: IThresholdHierarchy[];
  threshold_types: IThresholdType[];
};

interface Props {
  originalValues: any;
  errors?: Record<string, string[]>;
  numberFormat: any;
  field: IField;
  clientId: number;
  entity: string;
  record: RecordFormEntity;
  config: FormFieldConfig;
  isDisabled?: boolean;
  border?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onValueChange(
    state: any
  ): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
};

class ComprehensiveThresholdField extends React.Component<Props> {

  componentDidMount = () => {
    const { field, originalValues } = this.props;
    this.validate(field.values, originalValues);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values, originalValues);
    }
  };

  validate = (values: any[], originalValues: any[]) => {
    const { field, config, setFieldModifiedMessage, setFieldErrorMessage } = this.props;

    const fieldKey = this.getFieldKey();
    const errors = this.getErrors(field);
    let modified = {};

    if (!_.isEqual(values, originalValues)) {
      const modifiedRows = this.getModified(values, originalValues);
      if (!_.isEmpty(modifiedRows)) {
        modified = modifiedRows;
      } else {
        modified = { 'dummy': 'object' };
      }
    }

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };

    setFieldErrorMessage(fieldKey, errorMessage);
    setFieldModifiedMessage(fieldKey, modifiedMessage);
  };

  getFieldKey = (): string => {
    return `${this.props.field.id}_${this.props.config.fieldIndex || 0}_${this.props.field.type}`;
  };

  getErrors = (field: IField) => {
    return field.values.filter((_value: any) => {
      if (!!_value.threshold_type) {
        const selectedType = field.threshold_types.find((_thresholdType: IThresholdType) => _thresholdType.id === _value?.threshold_type);
        return selectedType?.value_required && !_value.threshold_value;
      }
      return false;
    }).map((_value: any) => {
      return `${_value.target_bundle}-${_value.target_type}-${_value.threshold_category}`;
    });
  };

  hasError = (fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>, rowKey: string): boolean => {
    return _.has(fieldErrorMessages, [this.getFieldKey(), 'errors']) && fieldErrorMessages[this.getFieldKey()]['errors'].some((error: string) => error === rowKey);
  };

  isModified = (fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>, rowKey: string, fieldKey: string): boolean => {
    return _.has(fieldModifiedMessages, [this.getFieldKey(), 'modified', rowKey]) && fieldModifiedMessages[this.getFieldKey()]['modified'][rowKey].includes(fieldKey);
  };

  getModified = (values: any, originalValues: any) => {
    const modified: any = {};

    if (!_.isEqual(values, originalValues)) {
      const fieldKeys = ['threshold_type', 'threshold_value'];

      values.forEach((_value: any) => {

        const valueKey = `${_value.target_bundle}-${_value.target_type}-${_value.threshold_category}`;

        if (originalValues.some((_originalValue: any) => _originalValue.target_bundle === _value.target_bundle && _originalValue.target_type === _value.target_type && _originalValue.threshold_category === _value.threshold_category)) {
          const originalValue = originalValues.find((_originalValue: any) => _originalValue.target_bundle === _value.target_bundle && _originalValue.target_type === _value.target_type && _originalValue.threshold_category === _value.threshold_category);
          if (!_.isEqual(_value, originalValue)) {
            Object.keys(_value)
              .filter((key: string) => fieldKeys.includes(key))
              .forEach((key: string) => {
                if (!originalValue) {
                  modified[valueKey] = fieldKeys;
                } else if (_value[key] !== originalValue[key]) {
                  if (!!modified[valueKey] && !modified[valueKey].includes(key)) {
                    modified[valueKey] = modified[valueKey].concat(key);
                  } else {
                    modified[valueKey] = [key];
                  }
                }
              });
          }
        } else {
          modified[valueKey] = fieldKeys;
        }
      });
    }

    return modified;
  };

  renderThresholdTable = (threshold: IThresholdHierarchy) => {
    const { field, numberFormat, isDisabled, config, fieldErrorMessages, fieldModifiedMessages } = this.props;
    const columns = [
      {
        key: 'record_type',
        title: 'Record Type',
        width: '33%',
        render: (recordType: IThresholdHierarchyRecordType) => {
          return (
            <div>{ recordType?.title || '-' }</div>
          );
        }
      },
      {
        key: 'comprehensive_threshold',
        title: 'Comprehensive Threshold',
        width: '33%',
        render: (recordType: IThresholdHierarchyRecordType) => {
          const value: any = field.values.find((_value: any) => _value.target_bundle === recordType.target_bundle && _value.target_type === recordType.target_type && _value.threshold_category === threshold.id);
          const isModified: boolean = this.isModified(fieldModifiedMessages, `${value?.target_bundle}-${value?.target_type}-${value?.threshold_category}`, 'threshold_type');

          return (
            <Select
              dropdownMatchSelectWidth={ false }
              placeholder={ 'Please Select' }
              className={ classNames('Select-Field', {
                'Select-Field--has-warning border-warning': isModified,
              }) }
              allowClear
              onChange={(thresholdTypeId: string) => {
                // Remove current value
                const newState = _.cloneDeep(field.values).filter((_value: any) => !(_value.target_bundle === recordType.target_bundle && _value.target_type === recordType.target_type && _value.threshold_category === threshold.id));
                const thresholdType = field.threshold_types.find((_threshold_type: IThresholdType) => _threshold_type.id === thresholdTypeId);

                if (thresholdType) {
                  const newValue: any = {
                    target_type: recordType.target_type,
                    target_bundle: recordType.target_bundle,
                    threshold_category: threshold.id,
                    threshold_type: thresholdType.id,
                    threshold_value: null
                  };

                  // Add it back again
                  newState.push(newValue);
                }

                this.props.onValueChange(newState);
              }}
              value={ value?.threshold_type }
            >
              { !_.isEmpty(field?.threshold_types) && field.threshold_types.map((thresholdType: IThresholdType) => (
                <Select.Option key={ thresholdType.id } value={ thresholdType.id }>
                  { thresholdType.title }
                </Select.Option>
              ))}
            </Select>
          );
        }
      },
      {
        key: 'threshold_value',
        title: `Threshold Value (${ field.currency?.code ? field.currency.code : 'GBP' })`,
        width: '33%',
        render: (recordType: IThresholdHierarchyRecordType) => {
          const decimal = _.has(field, 'config.decimal') ? field.config.decimal : 2;
          const value: any = field.values.find((_value: any) => _value.target_bundle === recordType.target_bundle && _value.target_type === recordType.target_type && _value.threshold_category === threshold.id);

          const selectedType = field.threshold_types.find((_thresholdType: IThresholdType) => _thresholdType.id === value?.threshold_type);
          const currencySymbol = field?.currency?.symbol || '';
          const hasErrors: boolean = this.hasError(fieldErrorMessages, `${value?.target_bundle}-${value?.target_type}-${value?.threshold_category}`);
          const isModified: boolean = this.isModified(fieldModifiedMessages, `${value?.target_bundle}-${value?.target_type}-${value?.threshold_category}`, 'threshold_value');

          if (!selectedType?.value_required) {
            return <div className="ta-r">-</div>;
          }

          return (
            <NumberFormat
              { ...numberFormat }
              customInput={ Input }
              autoComplete="newpassword" // hack
              className={ classNames('Field Field-Number ta-r', {
              'Field--has-error border-danger': hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              prefix={ field.currency?.symbol ? `${field.currency.symbol} ` : undefined }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              required={ field.config.required }
              disabled={ isDisabled }
              defaultValue={ value?.threshold_value }
              placeholder={ '-' }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const index = _.cloneDeep(field.values).findIndex((_value: any) => _value.target_bundle === recordType.target_bundle && _value.target_type === recordType.target_type && _value.threshold_category === threshold.id);
                if (index !== -1) {
                  const _value = event.target.value !== '' ? parseFloat(event.target.value.replace(currencySymbol, '').replaceAll(',', '')) : null;
                  this.props.onValueChange(_.set(_.cloneDeep(field.values), index, {
                    ...value,
                    threshold_value: _value
                  }));
                }
              } }
            />
          );
        }
      },
    ];

    return (
      <FieldWrapper
        border
        col={ 12 }
        key={ threshold.id }
        label={ threshold.title }
      >
        <Table
          size={ 'small' }
          columns={ columns }
          dataSource={ threshold.record_types.map((recordTypes: IThresholdHierarchyRecordType, index: number) => {
            return {
              key: index,
              ...recordTypes,
            };
          } ) }
          pagination={ false }
        />
      </FieldWrapper>
    );
  };

  render = () => {
    const { field, config } = this.props;
    return (
      <FieldWrapper
        border
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        description={ field?.description }
        versionChanged={ !!field.config.version_changed }
      >
        { !_.isEmpty(field.threshold_hierarchy) ? (
          field.threshold_hierarchy.map((threshold_hierarchy: IThresholdHierarchy) => this.renderThresholdTable(threshold_hierarchy) )
        ) : (
          <span>-</span>
        ) }
      </FieldWrapper>
    );
  };
};

export default ComprehensiveThresholdField;
