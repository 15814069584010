// Libs
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Table, Tooltip, Typography, Modal, Pagination } from 'antd';
import FieldWrapper from 'components/form/field/field-wrapper';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import SpecificationSelectionModal from './SpecificationSelectionModal';
import Badge, { BadgeType } from 'components/badge';

// Icons
import { EditOutlined, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';

// Services
import { getFormatedDate, getUserSetting } from 'services/settings';

// Interfaces
import { RecordFormEntity } from 'types/entities';

const { Link } = Typography;

interface Props {
  record: RecordFormEntity;
  field: FormField;
  clientId: number;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string,
  ): void;
  onRefreshForm(field_id: string): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  showSpecificationSelectionModal: boolean;
  activeRowKey: string | null;
  currentPage: number;
  itemsPerPage: number;
};

class SpecificationRelationshipField extends Component<Props, State> {

  state: State = {
    showSpecificationSelectionModal: false,
    activeRowKey: null,
    currentPage: 1,
    itemsPerPage: 5,
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);

      if (!!field.config.refresh_on_change) {
        this.props.onRefreshForm(field.id);
      }
    }
  };

  validate = (state: any) => {
    const { originalState } = this.props;
    this.generateModifiedState(originalState, state, 'specification_relationship');
    this.generateErrorState(state, 'specification_relationship');
  };

  generateModifiedState = (pastValue: any, newValue: any, columnKey: string) => {
    const { setFieldModifiedMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue)) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (values: any[], columnKey: string) => {
    const { setFieldErrorMessage, field, config } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    let errors: any = [];

    if (!!field.config.required && _.isEmpty(values)) {
      errors.push('Cannot be empty');
    }

    if (!_.isEmpty(errors)) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  paginageItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  renderTableSummary = (itemsPerPage: number, currentPage: number, total: number) => {
    let start = ((itemsPerPage * currentPage) - itemsPerPage) + 1;
    let end = start + (itemsPerPage - 1);

    if (end < itemsPerPage) {
      end = itemsPerPage;
    } else if (end > total) {
      end = total;
    }

    return (
      <span>{ `Showing ${start}-${end} of ${total} item` }{ `${ total > 1 ? 's' : '' }` }</span>
    );
  };

  canSelectMultiple = () => {
    return this.props.field.config.cardinality !== 1;
  };

  renderDetailModal = (activeRowKey: string, serviceSpecifications: any) => {

    const serviceSpecification = serviceSpecifications.find((serviceSpecification: any) => serviceSpecification.key === activeRowKey);
    let properties = [];

    if (serviceSpecification && _.has(serviceSpecification, 'properties')) {
      properties = serviceSpecification.properties.map((property: any) => {
        return {
          'key': `${property.bundle}-${property.type}-${property.id}`,
          ...property,
        };
      });
    }

    return (
      <Modal
        centered
        visible
        title={ 'Properties' }
        onCancel={ () => this.setState({ activeRowKey: null }) }
        cancelText={ 'Close' }
        style={{ minWidth: 1000 }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          className="ov-a"
          style={{ height: 450 }}
          size={ 'small' }
          columns={ [
            {
              key: 'title',
              dataIndex: 'title',
              title: 'Property',
              width: 300,
              render: (__: any, property: any) => {
                return (
                  <RouterLink className='primaryColor' to={ property.path }>
                    { property.title }
                  </RouterLink>
                );
              },
              sorter: false,
              ellipsis: true,
            },
            {
              title: <span>Ownership</span>,
              width: 140,
              render: (__: any, property: any) => {
                if (!_.has(property, 'ownership') || _.isEmpty(property.ownership)) return <>-</>;
                return (
                  <span>
                    { property.ownership }
                  </span>
                );
              }
            },
            {
              title: <span>Location</span>,
              width: 180,
              render: (__: any, property: any) => {
                if (!_.has(property, 'location') || _.isEmpty(property.location)) return <>-</>;
                return (
                  <RouterLink to={ `${property.location.path}` }>
                    { property.location.title }
                  </RouterLink>
                );
              }
            },
            {
              title: <span>Region</span>,
              render: (__: any, property: any) => {
                if (!_.has(property, 'region_trees') || _.isEmpty(property.region_trees)) return <>-</>;
                return (
                  property.region_trees.map((regions: any, index: number) => (
                    <div key={ index }>
                      { regions.map((region: any, _index: number) => (
                        <span key={ _index }>
                          { regions.length === _index + 1 ?
                            (
                              <span>{ region.title }</span>
                            ) : (
                              <span><span>{ region.title }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                            )
                          }
                        </span>
                      ) ) }
                    </div>
                  ) )
                );
              }
            },
          ] }
          dataSource={ properties }
          expandable={{
            defaultExpandAllRows: true
          }}
        />
      </Modal>
    );
  };

  render = () => {
    const { record, field, config, clientId, border, state, isDisabled, onChange, fieldModifiedMessages, fieldErrorMessages } = this.props;
    const { showSpecificationSelectionModal, activeRowKey, currentPage, itemsPerPage } = this.state;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_specification_relationship`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const serviceSpecifications = state && state.map((value: any, index: number) => {
      return {
        key: index,
        ...value,
      };
    });
    const paginatedData = this.paginageItems(serviceSpecifications || [], currentPage, itemsPerPage);
    const columns = [
      {
        title: <span>Service Specifications</span>,
        width: 180,
        render: (row: any) => {
          return (
            <RouterLink className='primaryColor' to={ `${row.target_path}` }>
              { row.target_title }
            </RouterLink>
          );
        }
      },
      {
        title: <span>Properties</span>,
        width: 180,
        render: (row: any) => {
          const properties = _.has(row, 'properties') ? row.properties : [];
          if (_.isEmpty(properties)) {
            return (
              <span>-</span>
            );
          } else if (properties.length === 1) {
            return (
              <RouterLink className='primaryColor' to={ properties[0]['path'] }>
                { properties[0].title }
              </RouterLink>
            );
          } else {
            return (
              <>
                <Link onClick={ () => this.setState({ activeRowKey: row.key }) }>
                  <span>
                    { properties.length }
                  </span>
                </Link>
              </>
            );
          }
        }
      },
      {
        title: <span>Specification Type</span>,
        width: 180,
        render: (row: any) => {
          if (!_.has(row, 'specification_type') || !row.specification_type) return <>-</>;
          return (
            <span>{ row.specification_type.title }</span>
          );
        }
      },
      {
        title: <span>Locations</span>,
        width: 180,
        render: (row: any) => {
          const properties = _.has(row, 'properties') ? row.properties : [];
          const locations: any[] = properties
            .filter((property: any) => _.has(property, 'location') && !!property.location)
            .map((property: any) => property.location)
            .reduce((acc: any, cur: any) => [
              ...acc.filter((location: any) => location.id !== cur.id), cur
            ], []);

          if (_.isEmpty(properties) || _.isEmpty(locations)) {
            return (
              <span>-</span>
            );
          } else if (locations.length === 1) {
            return (
              <RouterLink className='primaryColor' to={ locations[0].path }>
                { locations[0].title }
              </RouterLink>
            );
          } else {
            return (
              <>
                <Link onClick={ () => this.setState({ activeRowKey: row.key }) }>
                  { locations.length }
                </Link>
              </>
            );
          }
        }
      },
      {
        title: <span>Regions</span>,
        width: 300,
        render: (row: any) => {
          const properties = _.has(row, 'properties') ? row.properties : [];
          if (_.isEmpty(properties)) {
            return (
              <span>-</span>
            );
          } else if (properties.length === 1) {
            return (
              properties[0].region_trees.map((regions: any, index: number) => (
                <div key={ index }>
                  { regions.map((region: any, _index: number) => (
                    <span key={ _index }>
                      { regions.length === _index + 1 ?
                        (
                          <span>{ region.title }</span>
                        ) : (
                          <span><span>{ region.title }</span><ArrowRightOutlined className="mL-10 mR-10" style={{ fontSize: 10 }} /></span>
                        )
                      }
                    </span>
                  ) ) }
                </div>
              ) )
            );
          } else {
            return (
              <>
                <Link onClick={ () => this.setState({ activeRowKey: row.key }) }>
                  <span>
                    { properties.length }
                  </span>
                </Link>
              </>
            );
          }
        }
      },
      {
        title: <span>Commencement Date</span>,
        width: 180,
        render: (row: any) => {
          if (!row.start_date || row.start_date === '0000-00-00 00:00:00') {
            return <>-</>;
          }
          return getFormatedDate(row.start_date, getUserSetting('date_format'));
        }
      },
      {
        title: <span>Expiry Date</span>,
        width: 180,
        render: (row: any) => {
          if (!row.end_date || row.end_date === '0000-00-00 00:00:00') {
            return <>-</>;
          }
          return getFormatedDate(row.end_date, getUserSetting('date_format'));
        }
      },
      {
        title: <span>Status</span>,
        width: 100,
        render: (row: any) => {

          let type = BadgeType.Success;
          let text = 'Active';

          if (!row.end_date || row.end_date === '0000-00-00 00:00:00') {
            return <Badge type={ type } text={ text } />;
          }

          if (moment(moment().format('YYYY-MM-DD HH:mm:ss')).isSameOrAfter(row.end_date)) {
            type = BadgeType.Danger;
            text = 'Expired';
          } else if (!!row.end_date && row.end_date !== '0000-00-00 00:00:00') {
            if (moment(moment().format('YYYY-MM-DD HH:mm:ss')).isAfter(row.start_date)) {
              type = BadgeType.Warning;
              text = 'Pending';
            }
          }

          return <Badge type={ type } text={ text } />;
        }
      }
    ];

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ 12 }
        label={ field.label }
        errors={ errors }
        hideErrorInfo
        required={ field.config.required }
        border={ border }
        description={ !!field.description && field.description }
        isModified={ _.isEmpty(errors) && isModified }
        refreshOnChange={ !!field.config.refresh_on_change }
        versionChanged={ !!field.config.version_changed }
        rightActions={[
          {
            node: (
              <>
                { !isDisabled &&
                  <EditOutlined
                    className="link fsz-md"
                    style={{ padding: '5px 10px'}}
                    onClick={ () => this.setState({ showSpecificationSelectionModal: true }) }
                  />
                }
                { !_.isEmpty(errors) &&
                  <Tooltip
                    overlayClassName="text-white Field-Error"
                    placement="topRight"
                    title={ errors.join(', ') }
                  >
                    <InfoCircleOutlined className="Field-Error-Icon" />
                  </Tooltip>
                }
              </>
            )
          }
        ]}
      >
        <>
          <Table
            size={ 'small' }
            columns={ columns }
            dataSource={ paginatedData || [] }
            scroll={{
              x: !_.isEmpty(columns) ? columns.reduce((sum: any, { width } : { width: number }) => sum + width , 0) : (columns.length * 200),
              y: 400,
            }}
            pagination={ false }
          />
          { this.canSelectMultiple() && !_.isEmpty(serviceSpecifications) &&
            <div className='d-f jc-sb mT-10'>
              <div>
                { this.renderTableSummary(itemsPerPage, currentPage, serviceSpecifications.length) }
              </div>
              <div>
                <Pagination
                  size={ 'small' }
                  disabled={ _.isEmpty(paginatedData) }
                  showSizeChanger={ false }
                  current={ currentPage }
                  total={ serviceSpecifications.length }
                  pageSize={ itemsPerPage }
                  onChange={ page => {
                    this.setState({
                      currentPage: page
                    });
                  } }
                />
              </div>
            </div>
          }
        </>
        { showSpecificationSelectionModal &&
          <SpecificationSelectionModal
            record={ record }
            field={ field }
            clientId={ clientId }
            onSave={ (_field: FormField) => {
              this.setState({ showSpecificationSelectionModal: false }, () => {
                onChange(field, _field.values, config);
              });
            } }
            onClose={ () => this.setState({ showSpecificationSelectionModal: false }) }
          />
        }
        { activeRowKey !== null && this.renderDetailModal(activeRowKey, serviceSpecifications) }
      </FieldWrapper>
    );
  };
};

export default SpecificationRelationshipField;
